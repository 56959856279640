.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
}
.blog-parent {
  max-width: 450px;
  width: 70%;
}
.blog-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0px 20px 0px #1f2235;
}
.blog-card-image {
  min-height: 192px;
  max-height: 192px;
  background-image: url("../../assets/Blogs/packagemanagers.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
.blog-card-image-two {
  min-height: 192px;
  max-height: 192px;
  background-image: url("../../assets/Blogs/react19.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-card-image-three {
  min-height: 192px;
  max-height: 192px;
  background-image: url("../../assets/Blogs/miner.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-profile {
  min-height: 300px;
  /* width: 30%;
  background-image: url("../../assets/Blogs/packagemanagers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
}
.blog-details {
  width: 100%;
  text-align: justify;
  padding: 30px;
}
.blog-description {
  font-size: 12px;
}
.blog-highlights {
  padding-bottom: 20px;
}
.highlight-heading {
  font-family: "Poppins Bold";
  font-size: 14px;
  margin: 0 0 5px 0;
}
.highlight {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 12px;
}
.highlight-blob {
  height: 12px;
  width: 12px;
  margin: 0 10px 0 0;
  background-color: #ff5823;
  border-radius: 50%;
}
.blog-options .highlighted-btn {
  margin: 0 0 0 30px;
}
/*responsiveness ipad pro*/
@media only screen and (max-width: 1110px) {
  .blog-parent {
    width: 88%;
  }
}
/*ipad*/
@media only screen and (max-width: 898px) {
  .blog-profile {
    display: none;
  }
  .blog-details {
    width: 100%;
  }
  /* .blog-card-image {
    min-height: 160px;
    max-height: 160px;
  } */
}
@media only screen and (max-width: 466px) {
  .blog-options {
    display: flex;
    flex-direction: column;
  }
  .blog-options .highlighted-btn {
    margin: 24px 0 0 0 !important;
  }
  .blog-options button {
    width: 100%;
  }
  .blog-options a {
    width: 100%;
  }
  .blog-container {
    margin: 0px 0 50px;
  }
  .blog-card-image {
    min-height: 160px;
    max-height: 160px;
  }
}
