.resume-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: fit-content;
  margin: 120px 0;
}
/* .resume-content {
  box-shadow: 0px 0px 20px 0px #1f2235;
} */
.resume-card {
  display: flex;
  height: 360px;
  width: 1000px;
  /* background-color: tomato; */
}
/*resume bullets svg icons*/
.resume-bullets {
  box-shadow: 15px 0 9px -15px #1f2235;
  height: 100%;
  width: 320px;
}
.resume-bullet-details {
  flex-grow: 1;
  width: 600px;
  overflow: hidden;
  padding: 0 0 0 80px;
}
.experience-description {
  margin: 10px 0 0 0;
  text-align: justify;
  max-width: 100%;
}
.resume-details-carousal {
  transition: transform 1s ease-out;
}
.bullet-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}
.bullets {
  width: 80%;
  position: relative;
  z-index: 2;
}
.bullet {
  display: flex;
  align-items: center;
  background-color: #1f2235;
  height: 40px;
  margin: 15px 0;
  padding: 0 8px;
  border-radius: 20px;
  width: 30px;
  transition: width 0.6s ease;
  cursor: pointer;
}
.bullet-label {
  font-size: 14px;
  white-space: nowrap;
  font-family: "Poppins SemiBold";
}
.bullet-logo {
  height: 16px;
  margin: 0 30px 0 0;
}
.bullet-icons {
  width: 34px;
  height: 55%;
  z-index: 1;
  background-color: #1f2235;
  position: absolute;
}
/*styles bullet background and length*/
.selected-bullet {
  color: white;
  width: 100%;
}
/*fixes the actual styling information container and makes more even */
.resume-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 360px;
  width: 100%;
  /* background-color: tomato; */
  overflow: scroll;
  white-space: pre-wrap;
}
.resume-heading {
  display: flex;
  flex-direction: column;
}
.resume-main-heading {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.resume-main-heading span {
  color: var(--dark-orange);
  font-size: 14px;
  font-family: "Poppins SemiBold";
}
.experience-description {
  max-width: 100%;
}
.heading-bullet {
  position: absolute;
  left: -30px;
  height: 15px;
  width: 15px;
  top: 5px;
  background-color: var(--dark-orange);
  border-radius: 50%;
}
.resume-description-text {
  font-size: 14px;
}
.resume-sub-heading {
  font-size: 14px;
  font-family: "Poppins SemiBold";
  color: #111;
  margin: 10px 0 0 0;
}
.resume-heading-description {
  font-size: 14px;
  text-align: justify;
}
.heading-date {
  background-color: var(--dark-orange);
  padding: 4px 12px;
  font-size: 12px;
  border-radius: 14px;
  color: white;
}
.skill-parent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
}
.programming-skills-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.skill-percentage {
  position: relative;
  height: 15px;
  width: 70%;
  background-color: var(--dark-orange);
}
.skill-percentage .active-percentage-bar {
  height: 15px;
  transition: 0.8s ease;
  position: absolute;
  background-color: #1f2235;
}
.skill-parent span {
  font-family: "Poppins SemiBold";
  font-size: 14px;
  color: var(--dark-orange);
}
@media only screen and (max-width: 1110px) {
  .resume-card {
    width: 880px;
  }
  .resume-bullet-details {
    padding: 0 0 0 70px;
  }
  .bullet-icons {
    height: 55%;
  }
}
@media only screen and (max-width: 1023px) {
  .resume-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bullets {
    width: 90%;
  }
  .resume-card {
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    align-items: center;
    width: 90%;
  }
  .resume-bullet-details {
    height: 360px;
    width: fit-content;
    padding: 0;
  }
  .resume-bullets {
    width: 100%;
    margin: 30px 0;
  }
  .selected-bullet {
    width: 100%;
  }
  .programming-skills-container {
    /* background-color: tomato; */
    margin-left: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .bullet-icons {
    height: 30%;
  }
}
@media only screen and (max-width: 800px) {
  .bullet-icons {
    height: 30%;
  }
}
/* galaxy s5 */
@media only screen and (max-width: 375px) {
  .bullet-icons {
    height: 30%;
  }
  .resume-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 360px;
    width: 100%;
    /* background-color: tomato; */
    overflow: scroll;
    white-space: pre-wrap;
  }
  .resume-container {
    margin: 50px 0 0 0;
  }
  .resume-card {
    margin: 0;
  }
}
