.footer-container {
  height: 17.8%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
}

.footer-parent {
  width: 100%;
  height: 100%;
  /* max-width: 1920px; */
}
.footer-parent img {
  max-width: 100%;
  height: 130px;
}

/*response ipad*/
@media only screen and (max-width: 1110px) {
  .footer-parent {
    display: flex;
    align-items: flex-end;
  }
}

@media only screen and (max-width: 425px) {
  .footer-parent img {
    height: 60px;
  }
}
